import { useEffect } from "react";
const useSound = (trigger) => {
  const alert = "alert1 alert2".split(" ")[Math.floor(Math.random() * 2)];
  useEffect(() => {
    const addSound = (name) => {
      const sound = name === "second" ? alert : "click";
      console.log(sound);
      const audio = document.getElementById(sound);
      if (!audio?.play || !audio.parentElement.getAttribute("active")) return;
      audio.play();
    };
    if (trigger === "alert") {
      addSound("second");
    } else {
      const btns = document.querySelectorAll("button");
      btns.forEach((btn) => btn.addEventListener("click", addSound));
      return () =>
        btns.forEach((btn) => btn.removeEventListener("click", addSound));
    }
  });
};

export default useSound;

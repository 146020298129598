import { useNavigate, useParams } from "react-router-dom";
import instructions from "../assets/instructions.json";
import randomKeys from "../module/random-keys";
import LayoutDefault from "./LayoutDefault";
import useSound from "../hooks/useSound";

const Start = () => {
  const param = (Number(useParams().id) || 1) - 1;
  const navigate = useNavigate();
  const id = param > 2 ? 0 : param;
  const getRandomKey = randomKeys();
  const instructionsArray = Object.entries(instructions);
  const currInstruction = instructionsArray[id];
  const changeInstruction = (text) => {
    if (text === "next") {
      const next = id + 2;
      navigate(`/start/${next}`);
    } else {
      const prev = id;
      navigate(`/start/${prev}`);
    }
  };
  useSound("click");
  return (
    <>
      <LayoutDefault
        Main={
          <div className="msg-wrapper instruction">
            <h2>{currInstruction[0]}</h2>
            <ul className="info-list">
              {currInstruction[1].split("\n").map((text) => (
                <li key={getRandomKey()}>
                  {text.includes("<br/>") ? (
                    <>
                      {text.split("<br/>").map((line) => (
                        <span key={getRandomKey()}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </>
                  ) : (
                    <>{text}</>
                  )}
                </li>
              ))}
            </ul>
          </div>
        }
        Footer={
          <div className="navs instruction-navs">
            <button
              onClick={(e) => {
                if (id === 2) return navigate("/symbols");
                changeInstruction(e.target.textContent.toLowerCase());
              }}
            >
              {id === 2 ? "Start" : "Next"}
            </button>
            <button
              onClick={(e) => {
                if (!id) return navigate("/");
                changeInstruction(e.target.textContent.toLowerCase());
              }}
            >
              Back
            </button>
          </div>
        }
      />
    </>
  );
};

export default Start;

import click from "../assets/click.mp3";
import crystal from "../assets/crystal.mp3";
import roar from "../assets/demon-roar.mp3";

const sounds = () => {
  return {
    first: click,
    second: [crystal, roar],
  };
};

export default sounds;

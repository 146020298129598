import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Start,
  Secret,
  Options,
  Symbol,
} from "./components/all-components";
import useSoundState from "./hooks/useSoundState";
import useSound from "./hooks/useSound";
import sounds from "./module/sound";

const App = () => {
  const [soundOn, setSoundOn] = useSoundState();
  const { first, second } = sounds();
  useSound("click");
  return (
    <div className="App">
      <aside style={{ display: "none" }} active={soundOn ? "true" : null}>
        <audio preload="auto" id="click" src={first}></audio>
        <audio preload="auto" id="alert1" src={second[0]}></audio>
        <audio preload="auto" id="alert2" src={second[1]}></audio>
      </aside>
      <div className="container">
        <Router>
          <Routes>
            <Route path="/start">
              <Route index element={<Start />} />
              <Route path=":id" element={<Start />} />
            </Route>
            <Route path="/secret" element={<Secret />} />
            <Route
              path="/options"
              element={<Options soundOn={soundOn} setSoundOn={setSoundOn} />}
            />
            <Route path="/symbols" element={<Symbol />} />
            <Route path="/*" element={<Home />}></Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;

import randomSymbols from "../module/random-symbols";
import Sessions from "./Sessions";

const Symbol = ({ soundOn }) => {
  const symbolsObj = randomSymbols();
  const symbols = Object.entries(symbolsObj.others).sort((a, b) => a[0] - b[0]);
  return (
    <Sessions symbols={symbols} target={symbolsObj.target} soundOn={soundOn} />
  );
};

export default Symbol;

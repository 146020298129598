import { useState, useEffect } from "react";

const useSoundState = () => {
  const soundState = localStorage.getItem("sound")
    ? JSON.parse(localStorage.getItem("sound"))
    : true;
  const [soundOn, setSoundOn] = useState(soundState);
  useEffect(() => {
    localStorage.setItem("sound", JSON.stringify(soundOn));
    return () => {};
  }, [soundOn]);
  return [soundOn, setSoundOn];
};

export default useSoundState;

import LayoutDefault from "./LayoutDefault";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useSound from "../hooks/useSound";

const Options = ({ soundOn, setSoundOn }) => {
  const [interatedWith, setInteratedWith] = useState(false);
  const navigate = useNavigate();
  const handleSound = () => {
    if (!interatedWith) setInteratedWith(true);
    setSoundOn(!soundOn);
  };
  useSound("click");
  return (
    <LayoutDefault
      Main={
        <>
          <div className="msg-wrapper options">
            <section className="sound">
              <h3>Sound</h3>
              <button
                onClick={handleSound}
                className={
                  !interatedWith
                    ? `toggle-sound ${soundOn ? "on" : "off"}`
                    : `toggle-sound sound-${soundOn ? "on" : "off"}`
                }
              >
                <span></span>
              </button>
            </section>
          </div>
        </>
      }
      Footer={<button onClick={() => navigate("/")}>Back</button>}
    />
  );
};

export default Options;

import React from "react";

const LayoutDefault = ({ Main, Footer }) => {
  return (
    <section className="content">
      <h1>Pro's magic book</h1>
      {Main}
      {Footer}
    </section>
  );
};

export default LayoutDefault;

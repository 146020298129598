import useSound from "../hooks/useSound";
import LayoutDefault from "./LayoutDefault";
import { useNavigate } from "react-router-dom";

const Secret = () => {
  const navigate = useNavigate();
  useSound("click");
  return (
    <LayoutDefault
      Main={
        <>
          <div className="msg-wrapper secret">
            <h2>The secret behind the magic</h2>
            <p>
              Some people say it's an ai working behind the scenes.
              <br />
              Others say it's a demon watching you.
            </p>
            <p>Whatever the reason is, guess we might never really know.</p>
          </div>
        </>
      }
      Footer={
        <div className="navs secret-navs">
          <button>Buy secret</button>
          <button onClick={() => navigate("/")}>Back</button>
        </div>
      }
    />
  );
};

export default Secret;

const randomKey = (len = 16) => {
  const str = "1234567890qwertyuiopasdfghjklmnopqwerty";
  const makeKey = () => {
    let key = "";
    for (let i = 0; i < len; i++)
      key += str[Math.floor(Math.random() * str.length)];
    return key;
  };
  return () => makeKey();
};

export default randomKey;
